import { sendAndHandleStatusAndReason as e } from "../internal/communication.js";
import { createTeamsDeepLinkForCalendar as t } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { FrameContexts as o } from "./constants.js";
import { runtime as i } from "./runtime.js";
function m(t) {
  return new Promise(m => {
    if (n(i, o.content), !s()) throw new Error("Not supported");
    if (!t.itemId || !t.itemId.trim()) throw new Error("Must supply an itemId to openCalendarItem");
    m(e(r("v2", "calendar.openCalendarItem"), "calendar.openCalendarItem", t));
  });
}
function a(m) {
  return new Promise(a => {
    if (n(i, o.content), !s()) throw new Error("Not supported");
    const p = r("v2", "calendar.composeMeeting");
    i.isLegacyTeams ? a(e(p, "executeDeepLink", t(m.attendees, m.startTime, m.endTime, m.subject, m.content))) : a(e(p, "calendar.composeMeeting", m));
  });
}
function s() {
  return !(!n(i) || !i.supports.calendar);
}
export { a as composeMeeting, s as isSupported, m as openCalendarItem };