import { sendAndUnwrap as t, sendMessageToParent as n } from "../internal/communication.js";
import { errorCallNotStarted as r } from "../internal/constants.js";
import { createTeamsDeepLinkForCall as e } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as i } from "../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { FrameContexts as a, errorNotSupportedOnPlatform as s } from "./constants.js";
import { runtime as l } from "./runtime.js";
var m;
function c(c) {
  const d = o("v2", "call.startCall");
  return new Promise(o => {
    var f;
    if (i(l, a.content, a.task), !u()) throw s;
    if (!l.isLegacyTeams) return n(d, "call.startCall", [c], o);
    o(t(d, "executeDeepLink", e(c.targets, null === (f = c.requestedModalities) || void 0 === f ? void 0 : f.includes(m.Video), c.source)).then(t => {
      if (!t) throw new Error(r);
      return t;
    }));
  });
}
function u() {
  return !(!i(l) || !l.supports.call);
}
!function (t) {
  t.Unknown = "unknown", t.Audio = "audio", t.Video = "video", t.VideoBasedScreenSharing = "videoBasedScreenSharing", t.Data = "data";
}(m || (m = {}));
export { m as CallModalities, u as isSupported, c as startCall };