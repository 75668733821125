import { createAction, props } from '@ngrx/store';
import { DataSourceType } from '../services/data-service.factory';
import { CategoryItem, CustomizedCategoryItem } from './categories';
import {
    CategoryChosenSortField,
    CategoryChosenSortOrder,
    CategoryChosenViewMode,
    DataFromDb,
    DirectoryUser,
    DirModals,
    GroupByField,
    SortField,
    SortOrder,
    UsersViewMode
} from './data.models';

export const loadUsers = createAction('[Data] Load Users');
export const reloadUsers = createAction('[Data] Reload users');
export const reloadUsersSuccess = createAction(
    '[Data] Reload Users Success',
    props<{ users: DirectoryUser[]; dataSourceType: DataSourceType }>()
);
export const reloadUsersFailure = createAction('[Data] Reload Users Failure', props<{ error: unknown }>());
export const reloadUsersTopbar = createAction('[Data] Reload users from topbar');
export const reloadUsersAfterConsent = createAction('[Data] Reload users after consent');

export const loadUsersProgress = createAction('[Data] Load Users progress', props<{ progress: number }>());

export const loadUsersSuccess = createAction(
    '[Data] Load Users Success',
    props<{ users: DirectoryUser[]; dataSourceType: DataSourceType }>()
);
export const loadUsersFailure = createAction('[Data] Load Users Failure', props<{ error: unknown }>());
export const loadIndividualUsersSuccess = createAction(
    '[Data] Load Individual Users Success',
    props<{ users: DirectoryUser[]; dataSourceType: DataSourceType }>()
);
export const loadIndividualUsersFailure = createAction(
    '[Data] Load Individual Users Failure',
    props<{ error: unknown }>()
);
export const loadIndividualUsersNoResults = createAction('[Data] Load Individual Users No Results');

export const hasRefreshedData = createAction('[Data] Has refreshed data');

export const updateSearchString = createAction('[Data] Update search string', props<{ searchString: string }>());

export const setUsersPageNumber = createAction('[Data] Set users page number', props<{ pageNumber: number }>());
export const setUsersPageSize = createAction('[Data] Set users page size', props<{ pageSize: number }>());
export const setUsersViewMode = createAction('[Data] Set users view mode', props<{ viewMode: UsersViewMode }>());
export const setSortField = createAction('[Data] Set sort field', props<{ sortField: SortField }>());
export const setSortOrder = createAction('[Data] Set sort order', props<{ sortOrder: SortOrder }>());
export const setGroupBy = createAction('[Data] Set group by', props<{ groupBy: GroupByField }>());
export const setCategoryItemsViewMode = createAction(
    '[Data] Set category items view mode',
    props<{ categoryChosenViewMode: CategoryChosenViewMode }>()
);
export const setCategorySortField = createAction(
    '[Data] Set category sort field',
    props<{ categoryChosenSortField: CategoryChosenSortField }>()
);
export const setCategorySortOrder = createAction(
    '[Data] Set category sort order',
    props<{ categoryChosenSortOrder: CategoryChosenSortOrder }>()
);

export const loadDataFromDb = createAction('[Data] Load data from DB');
export const loadDataFromDbSettings = createAction('[Data] Load data from DB for settings');
export const loadDataFromDbDirectory = createAction('[Data] Load data from DB for directory');
export const loadDataFromDbSuccess = createAction(
    '[Data] Load data from DB success',
    props<{ dataFromDb: DataFromDb }>()
);
export const loadDataFromDbFailure = createAction('[Data] Load data from DB failure', props<{ error: unknown }>());
export const toggleExpandDepartments = createAction('[Data] Toggle expand departments');
export const toggleExpandCategory = createAction('[Data] Toggle expand offices', props<{ id: number }>());

export const dialogHide = createAction('[Data] Hide modal dialog');
export const dialogShow = createAction(
    '[Data] Show modal dialog',
    props<{ dialog: DirModals; options?: { initialState?: unknown; [param: string]: unknown } }>()
);

export const showHelpDialog = function (topic: string) {
    return dialogShow({ dialog: DirModals.Help, options: { initialState: { topic } } });
};

export const saveCategoryItem = createAction(
    '[Data] Save category item',
    props<{ categoryItem: CustomizedCategoryItem }>()
);
export const saveCategoryItemSuccess = createAction(
    '[Data] Save category item success',
    props<{ savedCategoryItems: CustomizedCategoryItem[] }>()
);

export const rebuildCategoriesFromDb = createAction('[Data] Rebuild categories from DB');
export const rebuildCategoriesFromDbSuccess = createAction(
    '[Data] Rebuild categories from DB success',
    props<{ dataFromDb: DataFromDb }>()
);
export const rebuildCategoriesFromDbWithUsers = createAction(
    '[Data] Rebuild categories from DB with users already loaded'
);
export const rebuildCategoriesFromDbWithUsersSuccess = createAction(
    '[Data] Rebuild categories from DB with users already loaded success',
    props<{ dataFromDb: DataFromDb }>()
);

export const deleteDb = createAction('[Data] Delete DB');
export const deleteDbSuccess = createAction('[Data] Delete DB success');

export const toggleFavorite = createAction('[Data] Toggle favorite', props<{ id: string }>());
export const toggleFavoriteSuccess = createAction('[Data] Toggle favorite success', props<{ favorites: string[] }>());
//export const toggleFavoriteFailure = createAction('[Data] Toggle favorite failure', props<{ error: any }>());

export const setDataSource = createAction(
    '[Data] Set data source',
    props<{ dataSourceId?: number; chartId?: string; isFromToc: boolean }>()
);

export const setFilterLetter = createAction('[Data] Set filter letter', props<{ filterLetter: string | null }>());

export const noOp = createAction('[Data] No Op');
