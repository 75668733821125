import { Injectable, OnDestroy } from '@angular/core';
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    of,
    Subject,
    take,
    takeUntil,
    tap
} from 'rxjs';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Feature, FeatureDefinitions, Scopes } from '../components/features/features.models';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

/**
 * This service checks whether scopes have been granted to the user.
 */
@Injectable({ providedIn: 'root' })
export class FeatureService implements OnDestroy {
    private defaultScopes = ['openid', 'profile', 'email', 'offline_access'];
    private collectedScopes = [];
    private availableScopesSubject = new BehaviorSubject<string[]>([]);
    private avilableFeaturesSubject = new BehaviorSubject<Feature[]>([]);
    availableScopes$: Observable<string[]> = this.availableScopesSubject.asObservable();
    availableFeatures$: Observable<Feature[]> = this.avilableFeaturesSubject.asObservable();
    private readonly unsubscribe$ = new Subject<void>();
    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        //this.refreshAvailableScopes();
        this.msalBroadcastService.msalSubject$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((msg: EventMessage) => msg.eventType === EventType.INITIALIZE_END)
            )
            .subscribe(() => {
                this.refreshAvailableScopes();
            });
        this.msalBroadcastService.msalSubject$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
            )
            .subscribe((result) => {
                const payload = result.payload as AuthenticationResult;
                if (payload?.scopes && payload.scopes.length > 0) {
                    //check if any scopes are new and are not in collectedScopes
                    const newScopes = payload.scopes.filter(
                        (x) => !this.collectedScopes.some((s) => s.toLowerCase() === x.toLowerCase())
                    );
                    if (newScopes.length > 0) {
                        //add them to collected scopes
                        console.log('new scopes found', newScopes);
                        this.collectedScopes = [...new Set([...this.collectedScopes, ...newScopes])];
                        this.availableScopesSubject.next(this.collectedScopes);
                        this.refreshAvailableFeatures(this.collectedScopes);
                    }
                }
            });
    }

    /**
     * Refreshes the available scopes for the user
     */
    refreshAvailableScopes(scopes: string[] = this.defaultScopes) {
        //console.log('refreshAvailableScopes called', scopes);
        if (this.authService.instance.getActiveAccount() === null) {
            console.log('no active account');
            return;
        }
        this.authService
            .acquireTokenSilent({
                scopes: scopes
            })
            .pipe(
                map((result) => result.scopes),
                take(1),
                catchError((error) => {
                    console.log('refreshAvailableScopes error:', error);
                    return of<string[]>(this.availableScopesSubject.getValue());
                })
            )
            .subscribe((scopes) => {
                //debugger;
                //add to collected scopes, but keep only unique values
                this.collectedScopes = [...new Set([...this.collectedScopes, ...scopes])];
                this.availableScopesSubject.next(this.collectedScopes);
                this.refreshAvailableFeatures(this.collectedScopes);
            });
    }

    refreshAvailableScopesAsObservable(scopes: string[] = this.defaultScopes): Observable<string[]> {
        return this.authService
            .acquireTokenSilent({
                scopes: scopes
            })
            .pipe(
                map((result) => result.scopes),
                catchError((error) => {
                    console.log('refreshAvailableScopes error:', error);
                    return of<string[]>(this.availableScopesSubject.getValue());
                }),
                tap((scopes) => {
                    this.availableScopesSubject.next(scopes);
                    this.refreshAvailableFeatures(scopes);
                })
            );
    }

    /**
     * Refreshes the available features for the user
     */
    private refreshAvailableFeatures(scopes: string[]) {
        const availableFeatures: Feature[] = [];
        for (const feature in FeatureDefinitions) {
            //check if all scopes are granted
            if (this.hasAllScopesForFeature(feature as Feature, scopes)) {
                availableFeatures.push(feature as Feature);
            }
        }
        this.avilableFeaturesSubject.next(availableFeatures);
    }

    private hasAllScopesForFeature(feature: Feature, scopes: string[]): boolean {
        return FeatureDefinitions[feature].scopes.every((scope) => scopes.includes(scope));
    }

    /**
     * Returns true if user has all scopes for a feature
     * @param feature
     * @returns true if user has all scopes for a feature
     * */
    hasFeature(feature: Feature): Observable<boolean> {
        //check if all scopes are granted
        return this.availableFeatures$.pipe(
            map((features) => features.includes(feature)),
            distinctUntilChanged()
        );
    }

    /**
     * Returns true if all scopes are granted
     */
    hasAllScopes(requiredScopes: string[] = [Scopes.DirectoryReadAll]): Observable<boolean> {
        //console.log('hasAllScopes:', requiredScopes);
        return this.availableScopes$.pipe(
            map((scopes) => {
                // Check if all requiredScopes exists in scopes
                const hasRequiredScopes: boolean = requiredScopes.every((scope) =>
                    scopes.map((scope) => scope.toLowerCase()).includes(scope.toLowerCase())
                );
                //console.log('hasAllScope:', requiredScopes, hasRequiredScopes, scopes);

                return hasRequiredScopes;
            })
        );
    }

    /**
     * Returns true if all scopes are granted
     * @param requiredScopes
     * @returns true if all scopes are granted
     *  */
    checkAllScopes(requiredScopes: string[] = [Scopes.DirectoryReadAll]): Observable<boolean> {
        //console.log('hasAllScopes:', requiredScopes);
        return this.authService
            .acquireTokenSilent({
                scopes: requiredScopes
            })
            .pipe(
                map((result) => {
                    // Check if all requiredScopes exists in result.scopes
                    const hasRequiredScopes: boolean = requiredScopes.every((scope) =>
                        result.scopes.map((scope) => scope.toLowerCase()).includes(scope.toLowerCase())
                    );
                    //console.log('hasAllScope:', requiredScopes, hasRequiredScopes, result.scopes);

                    return hasRequiredScopes;
                }),
                catchError((error) => {
                    console.log('hasAllScope error:', error);
                    return of(false);
                })
            );
    }

    /**
     * Returns true if any of the scopes are granted
     * @param requiredScopes
     * @returns true if any of the scopes are granted
     */
    hasAnyScope(requiredScopes: string[] = [Scopes.DirectoryReadAll]): Observable<boolean> {
        return this.authService
            .acquireTokenSilent({
                scopes: this.defaultScopes
            })
            .pipe(
                map((result) => {
                    // Check if any of the requiredScopes exists in result.scopes
                    const hasAnyRequiredScopes: boolean = requiredScopes.some((scope) =>
                        result.scopes.map((scope) => scope.toLowerCase()).includes(scope.toLowerCase())
                    );
                    console.log('hasScope: ', requiredScopes, hasAnyRequiredScopes, result.scopes);
                    return hasAnyRequiredScopes;
                })
            );
    }

    /**
     * Returns an Observable of the current scopes granted to the user
     * @returns Observable<string[]> of current scopes
     */
    getUserScopes(): Observable<string[]> {
        return this.authService
            .acquireTokenSilent({
                scopes: this.defaultScopes,
                forceRefresh: false
            })
            .pipe(
                map((result) => {
                    // Map the scopes to lowercase and return the array of current scopes
                    const currentScopes: string[] = result.scopes.map((scope) => scope.toLowerCase());
                    //console.log('getUserScopes: ', currentScopes, result.scopes);
                    return currentScopes;
                }),
                catchError((error) => {
                    console.log('getUserScopes error: ', error);
                    return of<string[]>([]);
                })
            );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
