import { AppId as i } from "../public/appId.js";
import { hasScriptTags as t } from "./utils.js";
function n(i) {
  if (t(i)) throw new Error(`Potential app id (${i}) is invalid; it contains script tags.`);
  if (!a(i)) throw new Error(`Potential app id (${i}) is invalid; its length ${i.length} is not within the length limits (${o}-${r}).`);
  if (e(i)) throw new Error(`Potential app id (${i}) is invalid; it contains non-printable characters.`);
}
const o = 4,
  r = 256;
function a(i) {
  return i.length < r && i.length > o;
}
function e(i) {
  return [...i].some(i => {
    const t = i.charCodeAt(0);
    return t < 32 || t > 126;
  });
}
function s(t) {
  if (!(t instanceof i)) throw new Error(`Potential app id (${t}) is invalid; it is not an instance of AppId class.`);
}
export { e as doesStringContainNonPrintableCharacters, a as isStringWithinAppIdLengthLimits, r as maximumValidAppIdLength, o as minimumValidAppIdLength, s as validateAppIdInstance, n as validateStringAsAppId };