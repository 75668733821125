import { Injectable, InjectionToken } from '@angular/core';
import { Store, select } from '@ngrx/store';
import Dexie, { Table } from 'dexie';
import { filter, forkJoin, from, map, mergeMap, Observable, take, tap } from 'rxjs';
import { Category, ColumnSettingsModel, CustomizedCategoryItem, Filter, UserColumnSettings } from '../data/categories';
import {
    CategoryImage,
    initializeMetadata,
    DirectoryUser,
    FavoritesModel,
    Metadata,
    SavedCategoryItemPicture,
    SavedUserPhoto,
    TenantSettingsModel
} from '../data/data.models';
import * as fromRoot from '../reducers';
import { SettingsServerResponse, TenantSettings, fillMetadata } from '../settings/settings.models';
import { DefaultAzureSourceChartId } from '../utils/data-source-helper';
import { logger } from '../utils/logger';
import { NGXLogger } from 'ngx-logger';
import * as settingsActions from '../settings/settings.actions';
import { ReportConfigData, ReportResult } from '../integrity/checks';
import { DirectoryUserModification } from '../integrity/integrity.models';

export const TENANT_ID = new InjectionToken<string>('tenantId');
@Injectable({
    providedIn: 'root'
})
export class AppDB {
    public instance: DbInstance;
    constructor(
        private store: Store,
        private logger: NGXLogger
    ) {
        this.logger.warn('db service constructor');
        store
            .pipe(
                select(fromRoot.selectDbServiceDataSourceData),
                filter((x) => x != null),
                take(1)
            )
            .subscribe(({ user, isFromToc, chartId }) => {
                this.logger.warn('constructing db instance');
                if (isFromToc) {
                    this.logger.warn('WE ARE IN TOC MODE');
                }
                let dbId = user.tenantId;
                //let selectedId = DataSourceHelper.GetSelectedDataSourceChartId();
                if (chartId != null && chartId != DefaultAzureSourceChartId) {
                    dbId = `${user.tenantId}-${chartId}`;
                }
                this.instance = new DbInstance(dbId);
            });
        // combineLatest([
        //     store.select(fromRoot.selectUser).pipe(filter((x) => x != null)),
        //     store.select(fromRoot.selectIsFromToc),
        //     store.select(fromRoot.selectRouterState).pipe(filter((x) => x != null)),
        //     store.select(fromRoot.selectSelectedDataSourceChartId)
        // ]).subscribe(([user, isFromToc, routerState, selectedDataSourceChartId]) => {});
    }

    getUsers(): Observable<DirectoryUser[]> {
        //console.log('get users');
        logger.debug('get users');
        return from(this.instance.users.toArray()); //.pipe(map);
    }

    getFilteredUsers(): Observable<DirectoryUser[]> {
        //console.log('get filtered users');
        logger.debug('get filtered users');
        return from(this.instance.filteredUsers.toArray());
    }

    getFilters(): Observable<Filter[]> {
        //console.log('get filters');
        logger.debug('get filters');
        return from(this.instance.filters.toArray());
    }

    getTenantSettings(): Observable<TenantSettings> {
        logger.debug('get tenant settings');
        return from(this.instance.tenantSettings.get(1)).pipe(map((x) => x?.tenantSettings ?? null));
    }

    getFavorites(): Observable<string[]> {
        //console.log('get favorites');
        logger.debug('get favorites');
        return from(this.instance.favorites.get(1)).pipe(map((x) => x?.favorites ?? []));
    }

    getCategories(): Observable<Category[]> {
        //console.log('get categories');
        logger.debug('get categories');
        return from(this.instance.categories.toArray());
    }

    getSavedCategories(): Observable<Category[]> {
        //console.log('get saved categories');
        logger.debug('get saved categories');
        return from(this.instance.savedCategories.toArray()).pipe(
            tap((x) => logger.debug('saved categories count:', x.length))
        );
    }

    getSavedCategoryItems(): Observable<CustomizedCategoryItem[]> {
        logger.debug('get saved category items');
        return from(this.instance.savedCategoryItems.toArray());
    }

    clearMetadata() {
        logger.debug('clearing metadata');
        return from(this.instance.metadata.clear());
    }

    getMetadata(): Observable<Metadata> {
        logger.debug('get metadata');
        return from(this.instance.metadata.get(1));
    }

    saveMetadata(metadata: Metadata) {
        logger.debug('saving metadata');
        return from(this.instance.metadata.put(metadata, 1));
    }

    updateMetadata(response: SettingsServerResponse) {
        logger.debug('updating metadata');
        //load existing metadata and update the object
        return from(this.instance.metadata.get(1)).pipe(
            mergeMap((metadata) => {
                if (metadata == null) {
                    metadata = initializeMetadata();
                }
                fillMetadata(response, metadata);

                return from(this.instance.metadata.put(metadata, 1));
            })
        );
    }

    getUserColumnSettings(): Observable<UserColumnSettings> {
        logger.debug('get user column settings');
        return from(this.instance.columnSettings.get(1)).pipe(map((x) => x?.userColumnSettings));
    }

    saveUserColumnSettings(userColumnSettings: UserColumnSettings) {
        logger.debug('saving user column settings');
        return from(this.instance.columnSettings.put({ id: 1, userColumnSettings }, 1));
    }

    saveCategoryItems(categoryItems: CustomizedCategoryItem[]) {
        logger.debug('saving category items');
        return from(this.instance.savedCategoryItems.bulkPut(categoryItems));
    }

    replaceCategoryItems(categoryItems: CustomizedCategoryItem[]) {
        logger.debug('replacing category items');
        //create dexie transaction to clear items and then bulk put new
        return from(
            this.instance.transaction('rw', this.instance.savedCategoryItems, () => {
                this.instance.savedCategoryItems.clear();
                this.instance.savedCategoryItems.bulkPut(categoryItems);
            })
        );
    }

    getImage(id: string): Observable<CategoryImage> {
        logger.debug('get image');
        return from(this.instance.images.get(id));
    }

    saveImage(imageData: CategoryImage) {
        logger.debug('saving image');
        return from(this.instance.images.put(imageData, imageData.id));
    }

    clearUsers() {
        logger.debug('clearing users');
        return from(this.instance.users.clear());
    }

    replaceUsers(users: DirectoryUser[]) {
        logger.debug('replacing users');
        return from(
            this.instance.transaction('rw', this.instance.users, () => {
                this.instance.users.clear();
                this.instance.users.bulkPut(users);
            })
        );
    }

    clearFilteredUsers() {
        logger.debug('clearing filtered users');
        return from(this.instance.filteredUsers.clear());
    }

    clearFilters() {
        logger.debug('clearing filters');
        return from(this.instance.filters.clear());
    }

    clearFavorites() {
        logger.debug('clearing favorites');
        return from(this.instance.favorites.clear());
    }

    clearTenantSettings() {
        logger.debug('clearing tenant settings');
        return from(this.instance.tenantSettings.clear());
    }

    clearCategories() {
        logger.debug('clearing categories', new Date().getTime());
        return from(this.instance.categories.clear());
    }

    clearSavedCategories() {
        logger.debug('clearing saved categories');
        return from(this.instance.savedCategories.clear());
    }

    clearSavedCategoryItems() {
        logger.debug('clearing saved category items');
        return from(this.instance.savedCategoryItems.clear());
    }

    clearUserPhotos() {
        logger.debug('clearing user photos');
        return from(this.instance.userPhotos.clear());
    }

    clearImages() {
        logger.debug('clearing uploaded images');
        return from(this.instance.images.clear());
    }

    getReportConfig(): Observable<ReportConfigData> {
        logger.debug('get report config');
        return from(this.instance.reportConfig.get(1));
    }

    saveReportConfig(reportConfig: ReportConfigData) {
        logger.debug('save report config');
        return from(this.instance.reportConfig.put(reportConfig, 1));
    }

    clearReportConfig() {
        logger.debug('clear report config');
        return from(this.instance.reportConfig.clear());
    }

    getReportResult(): Observable<ReportResult> {
        logger.debug('get report results');
        return from(this.instance.reportResult.get(1));
    }

    saveReportResult(reportResult: ReportResult) {
        logger.debug('save report results');
        return from(this.instance.reportResult.put(reportResult, 1));
    }

    clearReportResult() {
        logger.debug('clear report results');
        return from(this.instance.reportResult.clear());
    }

    getUserModifications(): Observable<DirectoryUserModification[]> {
        logger.debug('get user modifications');
        return from(this.instance.userModifications.toArray());
    }

    saveUserModifications(modifications: DirectoryUserModification[]) {
        logger.debug('save user modifications');
        return from(this.instance.userModifications.bulkPut(modifications));
    }

    clearUserModifications() {
        logger.debug('clear user modifications');
        return from(this.instance.userModifications.clear());
    }

    saveSettingsSlicesToDatabase(settingsResponse: SettingsServerResponse) {
        const clearTasks: Observable<void>[] = [];
        const saveTasks: Observable<unknown>[] = [];
        let filters: Filter[] = undefined;
        let favorites: string[] = undefined;
        let tenantSettings: TenantSettings = undefined;
        let integrity: ReportConfigData = undefined;
        let userModifications: DirectoryUserModification[] = undefined;
        if (settingsResponse.settings) {
            let { categories, categoryItemsByCategory } = settingsResponse.settings.value;
            if (categories == null) {
                categories = [];
            }
            if (categoryItemsByCategory == null) {
                categoryItemsByCategory = {};
            }
            filters = settingsResponse.settings.value.filters;
            if (filters && !Array.isArray(filters)) {
                filters = [];
            }
            //fix by Vlad
            if (filters?.length === 0) {
                //if there are no filters we don't want to save them to the db
                filters = undefined;
            }
            const categoryItems = Object.keys(categoryItemsByCategory).reduce(
                (acc, key) => acc.concat(categoryItemsByCategory[key]),
                [] as CustomizedCategoryItem[]
            );
            console.log(
                'db.service saveCategoriesAndCategoryItems saving saved category items, count: ' + categoryItems.length
            );

            clearTasks.push(this.clearSavedCategories());
            clearTasks.push(this.clearSavedCategoryItems());
            saveTasks.push(from(this.instance.savedCategories.bulkPut(categories)));
            saveTasks.push(from(this.instance.savedCategoryItems.bulkPut(categoryItems)));
            saveTasks.push(this.saveUserColumnSettings(settingsResponse.settings.value.userColumnSettings));
        }

        filters = settingsResponse.filters?.value ?? filters;
        if (filters) {
            if (!Array.isArray(filters)) {
                filters = [];
            }
            clearTasks.push(this.clearFilters());
            saveTasks.push(from(this.instance.filters.bulkPut(filters)));
        }

        favorites = settingsResponse.favorites?.value ?? favorites;
        if (favorites) {
            clearTasks.push(this.clearFavorites());
            saveTasks.push(from(this.instance.favorites.put({ favorites, id: 1 }, 1)));
        }

        tenantSettings = settingsResponse.tenantsettings?.value ?? tenantSettings;
        if (tenantSettings) {
            clearTasks.push(this.clearTenantSettings());
            saveTasks.push(from(this.instance.tenantSettings.put({ tenantSettings, id: 1 }, 1)));
            this.store.dispatch(settingsActions.loadTenantSettingsSuccess({ tenantSettings }));
        }

        integrity = settingsResponse.integrity?.value ?? integrity;
        if (integrity) {
            clearTasks.push(this.clearReportConfig());
            saveTasks.push(from(this.instance.reportConfig.put(integrity, 1)));
        }

        userModifications = settingsResponse.modifications?.value ?? userModifications;
        if (userModifications) {
            clearTasks.push(this.clearUserModifications());
            saveTasks.push(from(this.instance.userModifications.bulkPut(userModifications)));
        }

        return forkJoin(clearTasks).pipe(
            tap(() => console.log('db.service saveCategoriesAndCategoryItems cleared saved categories')),
            mergeMap(() =>
                forkJoin(saveTasks).pipe(
                    mergeMap(() => {
                        if (integrity) {
                            this.store.dispatch(settingsActions.loadIntegritySettingsSuccess({ integrity }));
                        }
                        return this.updateMetadata(settingsResponse);
                    })
                )
            )
        );
    }

    deleteDatabase() {
        logger.info('deleting database');
        return from(this.instance.delete());
    }

    openDatabase() {
        logger.info('opening database');
        return from(this.instance.open());
    }
}

class DbInstance extends Dexie {
    users!: Table<DirectoryUser, string>;
    filteredUsers!: Table<DirectoryUser, string>;
    filters!: Table<Filter, number>;
    categories!: Table<Category, number>;
    savedCategories!: Table<Category, number>;
    savedCategoryItems!: Table<CustomizedCategoryItem, number>;
    userPhotos!: Table<SavedUserPhoto, string>;
    categoryItemPictures!: Table<SavedCategoryItemPicture, string>;
    metadata!: Table<Metadata, number>;
    images!: Table<CategoryImage, string>;
    columnSettings!: Table<ColumnSettingsModel, number>;
    favorites!: Table<FavoritesModel, number>;
    tenantSettings!: Table<TenantSettingsModel, number>;
    reportConfig!: Table<ReportConfigData, number>;
    reportResult!: Table<ReportResult, number>;
    userModifications!: Table<DirectoryUserModification, number>;

    constructor(tenantId: string) {
        super(`directoryDb-${tenantId}`);
        this.version(27).stores({
            users: '&id',
            filteredUsers: '&id',
            filters: '++id',
            categories: '++id',
            savedCategories: '++id',
            savedCategoryItems: '++id',
            userPhotos: '&id',
            categoryItemPictures: '&[categoryId+categoryItemId]',
            metadata: '&id',
            images: '&id',
            columnSettings: '&id',
            favorites: '++id',
            tenantSettings: '++id',
            reportConfig: '++id',
            reportResult: '++id',
            userModifications: '++id'
        });
        this.version(26).stores({
            userModifications: null
        });
        this.version(22).stores({
            users: '&id',
            filteredUsers: '&id',
            filters: '++id',
            categories: '++id',
            savedCategories: '++id',
            savedCategoryItems: '++id',
            userPhotos: '&id',
            categoryItemPictures: '&[categoryId+categoryItemId]',
            metadata: '&id',
            images: '&id',
            columnSettings: '&id',
            favorites: '++id',
            tenantSettings: '++id',
            reportConfig: '++id',
            reportResult: '++id',
            userModifications: '&id'
        });
    }
}

export const DbServiceFactory = (store: Store) => {
    //console.log('db factory');
    return store.select(fromRoot.selectUser).pipe(
        tap((u) => logger.warn('any user')),
        filter((x) => x != null),
        tap((x) => logger.warn('tap for', x)),
        map((user) => new AppDB(store, logger))
    );
};
