import { sendAndHandleSdkError as e } from "../../internal/communication.js";
import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { pagesTelemetryVersionNumber as n } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as r } from "../../internal/telemetry.js";
import { FrameContexts as a, errorNotSupportedOnPlatform as s } from "../constants.js";
import { runtime as i } from "../runtime.js";
function o(o) {
  return new Promise(p => {
    if (t(i, a.content, a.sidePanel, a.settings, a.task, a.stage, a.meetingStage), !g()) throw s;
    p(e(r(n, "pages.currentApp.navigateTo"), "pages.currentApp.navigateTo", o));
  });
}
function p() {
  return new Promise(o => {
    if (t(i, a.content, a.sidePanel, a.settings, a.task, a.stage, a.meetingStage), !g()) throw s;
    o(e(r(n, "pages.currentApp.navigateToDefaultPage"), "pages.currentApp.navigateToDefaultPage"));
  });
}
function g() {
  return !(!t(i) || !i.supports.pages) && !!i.supports.pages.currentApp;
}
export { g as isSupported, o as navigateTo, p as navigateToDefaultPage };