@if (this.dataSources.length > 0 && (fromToc$ | async) !== true) {
    <!-- {{lastDataRefreshed|json}}
    {{lastDataRefreshedString|json}} -->
    <div class="px-8 mb-2">
        @if (this.selectedSourceChartId === DefaultAzureSourceChartId) {
            <div class="d-flex flex-row align-items-center">
                @if (isAdmin) {
                    <a routerLink="/settings/dataSources">
                        <img
                            src="assets/img/entra/entra.svg"
                            alt="Azure"
                            class="logo-entra"
                            [tooltip]="'Click to change data source settings'" />
                    </a>
                } @else {
                    <img src="assets/img/entra/entra.svg" alt="Azure" class="logo-entra" />
                }
                <div class="ps-1" [tooltip]="'Last synced ' + lastDataRefreshedString">Connected to Entra ID</div>
            </div>
        } @else {
            <div class="d-flex flex-row align-items-center">
                @if (isAdmin) {
                    <a routerLink="/settings/dataSources">
                        <img
                            src="assets/img/toc/toc.png"
                            alt="Azure"
                            class="logo-entra"
                            [tooltip]="'Click to change data source settings'" />
                    </a>
                } @else {
                    <img src="assets/img/toc/toc.png" alt="Azure" class="logo-entra" />
                }
                <div class="ps-1" [tooltip]="'Last synced ' + lastDataRefreshedString">Connected to TeamOrgChart</div>
            </div>
        }
    </div>
}
