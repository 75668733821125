import { createAction, props } from '@ngrx/store';
import { UserPermission } from '../auth/auth.models';
import { CustomizedCategoryItem, Filter } from '../data/categories';
import {
    CategoriesItemsAndColumnSettings,
    SettingsServerResponse,
    TenantSettings,
    TocChartsResponse
} from './settings.models';
import * as fromSettings from './settings.reducer';
import { Metadata } from '../data/data.models';
import { DataSource, TDHttpError } from '../app.models';
import { ReportConfigData } from '../integrity/checks';
import { DirectoryUserModification } from '../integrity/integrity.models';

export const loadSettings = createAction('[Settings] Load Settings');

export const loadSettingsSuccess = createAction(
    '[Settings] Load Settings Success',
    props<{ response: SettingsServerResponse; metadata: Metadata }>()
);

export const loadSettingsFailure = createAction('[Settings] Load Settings Failure', props<{ error: unknown }>());

export const loadSettingsSuccessNoChange = createAction(
    '[Settings] Load Settings Success No Change',
    props<{ response: SettingsServerResponse; tenantSettings: TenantSettings }>()
);

export const loadTenantSettingsSuccess = createAction(
    '[Settings] Load Tenant Settings Success',
    props<{ tenantSettings: TenantSettings }>()
);

export const loadIntegritySettingsSuccess = createAction(
    '[Settings] Load Integrity Settings Success',
    props<{ integrity: ReportConfigData }>()
);

export const loadSettingsSuccessWithChange = createAction(
    '[Settings] Load Settings Success With Change',
    props<{ response: SettingsServerResponse }>()
);
export const updateSettings = createAction(
    '[Settings] Update Settings',
    props<{ settings: Partial<fromSettings.State> }>()
);

export const forceReloadSettings = createAction('[Settings] Force Reload Settings');

export const saveSettings = createAction(
    '[Settings] Save Settings',
    props<{ settings: CategoriesItemsAndColumnSettings }>()
);
export const saveSettingsSuccess = createAction(
    '[Settings] Save Settings Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveSettingsFailure = createAction('[Settings] Save Settings Failure', props<TDHttpError>());

export const saveFilters = createAction('[Settings] Save Filters', props<{ filters: Filter[] }>());
export const saveFiltersSuccess = createAction(
    '[Settings] Save Filters Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveFiltersFailure = createAction('[Settings] Save Filters Failure', props<TDHttpError>());

export const saveTenantSettings = createAction('[Settings] Save Tenant Settings', props<{ tenant: TenantSettings }>());
export const saveTenantSettingsSuccess = createAction(
    '[Settings] Save Tenant Settings Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveTenantSettingsFailure = createAction('[Settings] Save Tenant Settings Failure', props<TDHttpError>());

export const saveFavorites = createAction('[Settings] Save Favorites', props<{ favorites: string[] }>());
export const saveFavoritesSuccess = createAction(
    '[Settings] Save Favorites Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveFavoritesFailure = createAction('[Settings] Save Favorites Failure', props<TDHttpError>());

export const saveIntegrity = createAction('[Settings] Save Integrity', props<{ integrity: ReportConfigData }>());
export const saveIntegritySuccess = createAction(
    '[Settings] Save Integrity Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveIntegrityFailure = createAction('[Settings] Save Integrity Failure', props<TDHttpError>());

export const saveUserModifications = createAction(
    '[Settings] Save User Modifications',
    props<{ userModifications: DirectoryUserModification[] }>()
);
export const saveUserModificationsSuccess = createAction(
    '[Settings] Save User Modifications Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveUserModificationsFailure = createAction(
    '[Settings] Save User Modifications Failure',
    props<TDHttpError>()
);

export const saveAdmin = createAction('[Settings] Save Admin', props<{ objectId: string; userName: string }>());
export const saveAdminSuccess = createAction('[Settings] Save Admin Success', props<{ admins: UserPermission[] }>());
export const deleteAdmin = createAction('[Settings] Delete Admin', props<{ objectId: string }>());
export const deleteAdminSuccess = createAction(
    '[Settings] Delete Admin Success',
    props<{ admins: UserPermission[] }>()
);
export const loadAdmins = createAction('[Settings] Load Admins');
export const loadAdminsSuccess = createAction('[Settings] Load Admins Success', props<{ admins: UserPermission[] }>());

export const resetServerSettings = createAction('[Settings] Reset Server Settings');
export const resetServerSettingsSuccess = createAction('[Settings] Reset Server Settings Success');

export const saveCategoryItemsInSettings = createAction(
    '[Settings] Save Category Items In Settings',
    props<{ savedCategoryItems: CustomizedCategoryItem[] }>()
);
export const saveCategoryItemsInSettingsSuccess = createAction(
    '[Settings] Save Category Items In Settings Success',
    props<{ response: SettingsServerResponse }>()
);

export const checkTenantExists = createAction('[Settings] Check Tenant Exists', props<{ tenantId: string }>());
export const checkTenantExistsSuccess = createAction(
    '[Settings] Check Tenant Exists Success',
    props<{ exists: boolean }>()
);
export const checkTenantExistsFailure = createAction(
    '[Settings] Check Tenant Exists Failure',
    props<{ error: unknown }>()
);

export const fetchCharts = createAction('[Settings] Fetch Charts');
export const fetchChartsSuccess = createAction(
    '[Settings] Fetch Charts Success',
    props<{ charts: TocChartsResponse }>()
);
export const fetchChartsFailure = createAction('[Settings] Fetch Charts Failure', props<TDHttpError>());

export const connectToChart = createAction('[Settings] Connect To Chart', props<{ dataSource: DataSource }>());
export const connectToChartSuccess = createAction('[Settings] Connect To Chart Success');
export const connectToChartFailure = createAction('[Settings] Connect To Chart Failure', props<{ error: unknown }>());

export const removeDataSource = createAction('[Settings] Remove Data Source', props<{ id: number }>());
export const removeDataSourceSuccess = createAction('[Settings] Remove Data Source Success');
export const removeDataSourceFailure = createAction(
    '[Settings] Remove Data Source Failure',
    props<{ error: unknown }>()
);

export const updateDataSource = createAction('[Settings] Update Data Source', props<{ dataSource: DataSource }>());
export const updateDataSourceSuccess = createAction('[Settings] Update Data Source Success');
export const updateDataSourceFailure = createAction(
    '[Settings] Update Data Source Failure',
    props<{ error: unknown }>()
);
