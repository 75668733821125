var n;
function r() {
  if (void 0 === n) {
    var r = new ArrayBuffer(2),
      t = new Uint8Array(r),
      e = new Uint16Array(r);
    if (t[0] = 1, t[1] = 2, 258 === e[0]) n = "BE";else {
      if (513 !== e[0]) throw new Error("unable to figure out endianess");
      n = "LE";
    }
  }
  return n;
}
function t() {
  return void 0 !== global.location ? global.location.hostname : "";
}
function e() {
  return [];
}
function o() {
  return 0;
}
function u() {
  return Number.MAX_VALUE;
}
function i() {
  return Number.MAX_VALUE;
}
function a() {
  return [];
}
function f() {
  return "Browser";
}
function c() {
  return void 0 !== global.navigator ? global.navigator.appVersion : "";
}
function l() {
  return {};
}
function s() {
  return {};
}
function m() {
  return "javascript";
}
function p() {
  return "browser";
}
function v() {
  return "/tmp";
}
var d = v,
  g = "\n";
function w() {
  return "$HOME";
}
var b = {
  homedir: w,
  EOL: "\n",
  arch: m,
  platform: p,
  tmpdir: d,
  tmpDir: v,
  networkInterfaces: l,
  getNetworkInterfaces: s,
  release: c,
  type: f,
  cpus: a,
  totalmem: i,
  freemem: u,
  uptime: o,
  loadavg: e,
  hostname: t,
  endianness: r
};
export { g as EOL, m as arch, a as cpus, b as default, r as endianness, u as freemem, s as getNetworkInterfaces, w as homedir, t as hostname, e as loadavg, l as networkInterfaces, p as platform, c as release, v as tmpDir, d as tmpdir, i as totalmem, f as type, o as uptime };