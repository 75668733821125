<div class="symbol" [ngClass]="getSize()" [class]="classes">
    <img
        [src]="imageSrc()"
        dirImageFallback
        alt="user photo"
        *ngIf="!showDefaultPicture && !isImageLoading() && !showFallback(); else noImageFound" />
    <ng-template #noImageFound>
        <span *ngIf="size === 35"><i class="bi bi-person-circle fs-2x text-gray-800"></i></span>
        <span
            *ngIf="size !== 35"
            class="symbol-label bg-light-danger text-danger fw-bold"
            [ngClass]="{ 'size-100': size > 100, 'fs-2': size < 51, 'size-50': size > 49 && size < 101 }">
            {{ userInitials }}
        </span>
    </ng-template>
    <!-- <dir-chat *ngIf="size === 50" [mail]="directoryUser.mail"></dir-chat> -->
</div>
