@let isAdmin = isAdmin$ | async;
@let isEntra = isEntra$ | async;
<!--begin::Aside-->
<div
    role="button"
    tabindex="0"
    class="drawer-overlay"
    *ngIf="isDrawerOpen$ | async"
    (click)="closeDrawer()"
    (keydown.space)="closeDrawer()"
    (keydown.enter)="closeDrawer()"></div>
<div
    class="app-sidebar flex-column"
    [ngClass]="{ 'drawer drawer-start': isDrawerSize, 'drawer-on': isDrawerOpen$ | async }"
    [style.top.px]="isDrawerSize ? 0 : shiftDownPx"
    [style.margin-top.px]="(isDrawerOpen$ | async) ? -1 : 0">
    <!--begin::Brand-->
    <div class="app-sidebar-logo d-flex d-lg-flex flex-stack flex-shrink-0 px-8">
        <!--begin::Logo-->
        <a *ngIf="!hostedInTOC" routerLink="/directory">
            <img alt="Logo" src="assets/img/logo-text-{{ envName }}-{{ theme }}.svg" class="logo-text" />
        </a>
        <!--end::Logo-->
        <!--begin::Aside action-->
        <div class="d-block d-lg-block">
            <a
                *ngIf="isAdmin"
                routerLink="/integrity"
                class="btn btn-icon btn-active-color-primary w-auto px-0"
                [ngClass]="{ 'btn-color-danger': highlightIntegrity }"
                [tooltip]="highlightIntegrity ? 'New feature: Data Integrity' : 'Data Integrity'">
                <!--begin::Svg Icon | path: icons/duotune/coding/cod001.svg-->
                <i class="ki-duotone ki-chart-simple-2 fs-2 me-2 text-danger2">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                </i>
                <!--end::Svg Icon-->
            </a>
            <a routerLink="/settings" class="btn btn-icon btn-active-color-primary w-auto px-0" tooltip="Settings">
                <!--begin::Svg Icon | path: icons/duotune/coding/cod001.svg-->
                <span class="svg-icon svg-icon-1 svg-icon-gray-700 me-n1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            opacity="0.3"
                            d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                            fill="currentColor"></path>
                        <path
                            d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                            fill="currentColor"></path>
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </a>
        </div>
        <!--end::Aside action-->
    </div>
    <!--end::Brand-->
    <dir-data-source-widget></dir-data-source-widget>
    <!--begin::Aside menu-->
    <div class="app-sidebar-menu2 app-sidebar-menu-arrow2 hover-scroll-overlay-y my-2 my-lg-2 px-3">
        <!--begin::Menu-->
        <div
            class="menu menu-column menu-sub-indention menu-hover-primary menu-title-gray-800 menu-here-primary menu-active-primary menu-hover-bg menu-active-bg fw-bold">
            <div *ngIf="isEntra" class="menu-item" [class.here]="me.isActive">
                <a
                    routerLink="/profile"
                    routerLinkActive="here"
                    class="menu-link without-sub-menu"
                    #me="routerLinkActive"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <span class="menu-title">Me</span>
                </a>
            </div>
            <div class="menu-item" [class.here]="rlapeople.isActive">
                <a
                    class="menu-link without-sub-menu"
                    routerLink="/directory"
                    routerLinkActive="here"
                    #rlapeople="routerLinkActive"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <span class="menu-title">People</span>
                </a>
            </div>
            <div *ngIf="(favorites$ | async).length" class="menu-item" [class.here]="favpeople.isActive">
                <a
                    class="menu-link without-sub-menu"
                    routerLink="/directory/favorites"
                    routerLinkActive="here"
                    #favpeople="routerLinkActive"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <span class="menu-title">Favorites</span>
                </a>
            </div>
            <span *ngFor="let category of categories$ | async">
                <dir-category-menu-item
                    *ngIf="!category.isHidden"
                    [category]="category"
                    [isExpanded]="isExpanded(category)"
                    (toggleExpanded)="onToggleExpandCategory($event)"></dir-category-menu-item>
            </span>
        </div>
        <!--end::Menu-->
    </div>
    <!--end::Aside menu-->
</div>
<!--end::Aside-->
