import { appInitializeHelper as e } from "../../internal/appHelpers.js";
import { sendMessageToParent as r, sendAndHandleStatusAndReason as t } from "../../internal/communication.js";
import { registerHandlerHelper as n } from "../../internal/handlers.js";
import { ensureInitialized as o } from "../../internal/internalAPIs.js";
import { setCurrentFrameHelper as i, getConfigHelper as s, navigateCrossDomainHelper as a, isAppNavigationParametersObject as m, convertNavigateToAppParamsToAppNavigationParameters as p, convertAppNavigationParametersToNavigateToAppParams as u, shareDeepLinkHelper as c, pagesTelemetryVersionNumber as f } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as l } from "../../internal/telemetry.js";
import { isNullOrUndefined as g } from "../../internal/typeCheckUtilities.js";
import { createTeamsAppLink as d } from "../../internal/utils.js";
import { prefetchOriginsFromCDN as j } from "../../internal/validOrigins.js";
import { errorNotSupportedOnPlatform as k, FrameContexts as v } from "../constants.js";
import { runtime as x } from "../runtime.js";
import * as L from "./appButton.js";
export { L as appButton };
import * as F from "./backStack.js";
export { F as backStack };
import * as h from "./config.js";
export { h as config };
import * as C from "./currentApp.js";
export { C as currentApp };
import * as P from "./fullTrust.js";
export { P as fullTrust };
import * as y from "./tabs.js";
export { y as tabs };
var A, T;
function w(e) {
  const t = l(f, "pages.returnFocus");
  if (o(x), !z()) throw k;
  if (void 0 === e && r(t, "returnFocus", [!1]), "boolean" == typeof e) r(t, "returnFocus", [e]);else switch (e) {
    case T.PreviousLandmark:
    case T.GoToActivityFeed:
      r(t, "returnFocus", [!1, e]);
      break;
    case T.NextLandmark:
      r(t, "returnFocus", [!0, e]);
  }
}
function N(e) {
  n(l(f, "pages.registerFocusEnterHandler"), "focusEnter", e, [], () => {
    if (!z()) throw k;
  });
}
function b(e) {
  i(l(f, "pages.setCurrentFrame"), e);
}
function H(r, t, n) {
  j(), e(l(f, "pages.initializeWithFrameContext"), n).then(() => t && t()), b(r);
}
function S() {
  return s(l(f, "pages.getConfig."));
}
function D(e) {
  return a(l(f, "pages.navigateCrossDomain"), e);
}
function G(e) {
  return new Promise(r => {
    if (o(x, v.content, v.sidePanel, v.settings, v.task, v.stage, v.meetingStage), !z()) throw k;
    const n = l(f, "pages.navigateToApp");
    if (x.isLegacyTeams) {
      const o = m(e) ? e : p(e);
      r(t(n, "executeDeepLink", d(o)));
    } else {
      const o = m(e) ? u(e) : e;
      r(t(n, "pages.navigateToApp", o));
    }
  });
}
function E(e) {
  return c(l(f, "pages.shareDeepLink"), e);
}
function R(e) {
  n(l(f, "pages.registerFullScreenHandler"), "fullScreenChange", e, [], () => {
    if (!g(e) && !z()) throw k;
  });
}
function z() {
  return !(!o(x) || !x.supports.pages);
}
!function (e) {
  e[e.PreviousLandmark = 0] = "PreviousLandmark", e[e.NextLandmark = 1] = "NextLandmark", e[e.Read = 2] = "Read", e[e.Compose = 3] = "Compose";
}(A || (A = {})), function (e) {
  e[e.PreviousLandmark = 0] = "PreviousLandmark", e[e.NextLandmark = 1] = "NextLandmark", e[e.GoToActivityFeed = 2] = "GoToActivityFeed";
}(T || (T = {}));
export { A as EnterFocusType, T as ReturnFocusType, S as getConfig, H as initializeWithFrameContext, z as isSupported, D as navigateCrossDomain, G as navigateToApp, N as registerFocusEnterHandler, R as registerFullScreenHandler, w as returnFocus, b as setCurrentFrame, E as shareDeepLink };