import { sendAndHandleStatusAndReason as r } from "../internal/communication.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { FrameContexts as e } from "./constants.js";
import { runtime as n } from "./runtime.js";
function i(i) {
  return new Promise(m => {
    if (t(n, e.content), !l()) throw new Error("Not supported");
    if (!i.itemId || !i.itemId.trim()) throw new Error("Must supply an itemId to openMailItem");
    m(r(o("v2", "mail.openMailItem"), "mail.openMailItem", i));
  });
}
function m(i) {
  return new Promise(m => {
    if (t(n, e.content), !l()) throw new Error("Not supported");
    m(r(o("v2", "mail.composeMail"), "mail.composeMail", i));
  });
}
function l() {
  return !(!t(n) || !n.supports.mail);
}
var p;
!function (r) {
  r.New = "new", r.Reply = "reply", r.ReplyAll = "replyAll", r.Forward = "forward";
}(p || (p = {}));
export { p as ComposeMailType, m as composeMail, l as isSupported, i as openMailItem };