import { sendMessageToParent as t } from "../../internal/communication.js";
import { ensureInitialized as r } from "../../internal/internalAPIs.js";
import { pagesTelemetryVersionNumber as e } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as n } from "../../internal/telemetry.js";
import { FrameContexts as s, errorNotSupportedOnPlatform as o } from "../constants.js";
import { runtime as i } from "../runtime.js";
function l() {
  if (r(i, s.content), !p()) throw o;
  t(n(e, "pages.fullTrust.enterFullscreen"), "enterFullscreen", []);
}
function u() {
  if (r(i, s.content), !p()) throw o;
  t(n(e, "pages.fullTrust.exitFullscreen"), "exitFullscreen", []);
}
function p() {
  return !(!r(i) || !i.supports.pages) && !!i.supports.pages.fullTrust;
}
export { l as enterFullscreen, u as exitFullscreen, p as isSupported };