import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    Category,
    ColumnsViewType,
    ColumnType,
    CustomizedCategoryItem,
    Filter,
    FilterOperator,
    UserColumnSettings
} from '../data/categories';
import { DirectoryUser, Metadata, SettingsTimestamps, SortOrder } from '../data/data.models';
import { ReportConfigData } from '../integrity/checks';
import { DirectoryUserModification } from '../integrity/integrity.models';

export const SettingsType = {
    Settings: 'settings',
    Filters: 'filters',
    Favorites: 'favorites',
    TenantSettings: 'tenantsettings',
    Integrity: 'integrity',
    Modifications: 'modifications'
} as const;

export type SettingsType = (typeof SettingsType)[keyof typeof SettingsType];

export type SettingsDataMap = {
    [SettingsType.Settings]: CategoriesItemsAndColumnSettings;
    [SettingsType.Filters]: Filter[];
    [SettingsType.Favorites]: string[];
    [SettingsType.TenantSettings]: TenantSettings;
    [SettingsType.Integrity]: ReportConfigData;
    [SettingsType.Modifications]: DirectoryUserModification[];
};

export interface CategoriesItemsAndColumnSettings {
    categories: Category[];
    categoryItemsByCategory: Record<number, CustomizedCategoryItem[]>;
    userColumnSettings: UserColumnSettings;
    filters?: Filter[]; //for compatibility with old settings
}

export interface TenantSettings {
    isCalendarHidden: boolean;
    isScheduleHidden: boolean;
    displayNameSplit: DisplayNameSplit;
}

export interface SettingSlice<T> {
    value: T;
    timestamps: SettingsTimestamps;
}

export interface SettingsServerResponseSimple {
    settings?: SettingSlice<CategoriesItemsAndColumnSettings>;
    filters?: SettingSlice<Filter[]>;
    favorites?: SettingSlice<string[]>;
    tenantsettings?: SettingSlice<TenantSettings>;
    integrity?: SettingSlice<ReportConfigData>;
    modifications?: SettingSlice<DirectoryUserModification[]>;
}

export type SettingsServerResponse = {
    [K in keyof SettingsDataMap]?: SettingSlice<SettingsDataMap[K]>;
};

export const fillMetadata = (response: SettingsServerResponse, metadata: Metadata) => {
    if (!metadata.slices) {
        metadata.slices = {};
    }
    (Object.values(SettingsType) as SettingsType[]).forEach((key) => {
        const slice = response[key];
        if (slice) {
            metadata.slices[key] = slice.timestamps;
        }
    });
};

export type IColumnSettingsForm = {
    [key in ColumnsViewType]: FormArray<FormGroup<IColumnSetting>>;
};

export type IUserFieldSettingForm = {
    [key in ColumnsViewType]: FormArray<FormGroup<IUserFieldSetting>>;
};

export type IAllColumnSettingsForm = {
    selectedCategoryIndex: FormControl<number | null>;
    users: FormGroup<IUserFieldSettingForm>;
    categories: FormArray<FormGroup<IColumnSettingsForm>>;
};

export interface ICategoryForm {
    id: FormControl<number>;
    name: FormControl<string>;
    fieldName: FormControl<keyof DirectoryUser>;
    icon: FormControl<string>;
    slug: FormControl<string>;
    isHidden: FormControl<boolean>;
    columnDefinitions: FormArray<FormGroup<IColumnDefinition>>;
    defaultSortField: FormControl<string>;
    defaultSortOrder: FormControl<SortOrder>;
}

export interface IColumnSetting {
    originalName: FormControl<string>;
    name: FormControl<string>;
    isHidden: FormControl<boolean>;
}

export interface IUserFieldSetting {
    originalName: FormControl<keyof DirectoryUser>;
    name: FormControl<string>;
    isHidden: FormControl<boolean>;
}

export interface IColumnDefinition {
    name: FormControl<string>;
    type: FormControl<ColumnType>;
}

export const DefaultUserFieldNames: { [key in keyof DirectoryUser]: string } = {
    displayName: 'Display Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    givenName: 'Given Name',
    surname: 'Surname',
    mail: 'Email',
    mobilePhone: 'Mobile Phone',
    officeLocation: 'Office Location',
    jobTitle: 'Job Title',
    department: 'Department',
    city: 'City',
    state: 'State',
    country: 'Country',
    id: 'Id',
    faxNumber: 'Fax Number',
    accountEnabled: 'Account Enabled',
    businessPhones: 'Business Phone',
    companyName: 'Company Name',
    birthday: 'Birthday',
    employeeHireDate: 'Employee Hire Date',
    employeeId: 'Employee ID',
    employeeType: 'Employee Type',
    extensionAttribute1: 'Extension Attribute 1',
    extensionAttribute2: 'Extension Attribute 2',
    extensionAttribute3: 'Extension Attribute 3',
    extensionAttribute4: 'Extension Attribute 4',
    extensionAttribute5: 'Extension Attribute 5',
    extensionAttribute6: 'Extension Attribute 6',
    extensionAttribute7: 'Extension Attribute 7',
    extensionAttribute8: 'Extension Attribute 8',
    extensionAttribute9: 'Extension Attribute 9',
    extensionAttribute10: 'Extension Attribute 10',
    extensionAttribute11: 'Extension Attribute 11',
    extensionAttribute12: 'Extension Attribute 12',
    extensionAttribute13: 'Extension Attribute 13',
    extensionAttribute14: 'Extension Attribute 14',
    extensionAttribute15: 'Extension Attribute 15',
    streetAddress: 'Street Address',
    userPrincipalName: 'User Principal Name',
    pictureUrl: 'Picture URL',
    userType: 'User Type',
    managerId: 'Manager ID',
    managerDisplayName: 'Manager'
};

export type IFiltersForm = {
    filters: FormArray<FormGroup<IFilterForm>>;
};

export interface IFilterForm {
    name: FormControl<string>;
    isEnabled: FormControl<boolean>;
    complexConditions: FormArray<FormGroup<IComplexCondition>>;
}

export interface IComplexCondition {
    conditions: FormArray<FormGroup<ICondition>>;
}

export interface ICondition {
    fieldName: FormControl<keyof DirectoryUser>;
    values: FormArray<FormControl<string>>;
    operator: FormControl<FilterOperator>;
    isNegated: FormControl<boolean>;
}

export enum TocConnectionState {
    Disconnected = 'Disconnected',
    Connecting = 'Connecting',
    ChartsFetched = 'ChartsFetched',
    NotFound = 'NotFound',
    Error = 'Error',
    NoCharts = 'NoCharts'
}

export interface TocChartsResponse {
    adminEmail: string;
    chartCount: number;
    chartMetas: ChartMeta[];
    companyName: string;
    isAdmin: boolean;
    logoUrl: string;
}

export interface TocChartItem {
    BoxType: number;
    VirtualLevel: number;
    SearchName: string;
    UniqueId: string;
    ManagerId: string;
    OriginalManagerId: string;
    ManagerDisplayName: string | null;
    MappedId: string | null;
    MappedManagerDisplayName: string | null;
    DisplayName: string;
    JobTitle: string;
    Email: string;
    Phone: string;
    Group: string;
    City: string;
    Country: string;
    CompanyName: string;
    OfficeLocation: string;
    PictureUrl: string;
    IsAssistant: boolean;
    HasHiddenReports: boolean;
    IsCoordinator: boolean;
    IsJointManager: boolean;
    IsDropLevel: boolean;
    IsDropLevelParent: boolean;
    IsLeadershipTeamMember: boolean;
    Height: number;
    Width: number;
    IsDepartment: boolean;
    IsChartLink: boolean;
    IsVacantPosition: boolean;
    IsTempPosition: boolean;
    IsAdditionalPosition: boolean;
    IsUploadLocked: boolean;
    IsDiscriminatorDepartment: boolean;
    IsNavigationHidden: boolean;
    HasNavigateDownArrow: boolean;
    VacantPosition: string | null;
    IsChartAddition: boolean;
    Coordinators: unknown[];
    JointManagers: unknown[];
    RuleIds: number[];
    TOC_Impacts: {
        BoxColour: string;
    };
    TOC_Fields: Record<string, string>;
    AppliedTemplate: unknown | null;
    TOC_Sort: string[];
    MultiplePositions: unknown[];
    ChartLayout: unknown | null;
    Status: number;
    CoveredUniqueId: string | null;
    AnnotationCount: number;
    HeightAdvice: number;
}

export interface ChartMeta {
    id: string;
    name: string;
    chartView: number;
    createdByObjectId: string;
    dataSourceName: string;
    dataSourceType: number;
    entriesCount: number;
    isDeleted: boolean;
    isHiddenInUi: boolean;
    isPrivate: boolean;
    isPublic: boolean;
    isPromoted: boolean;
    order: number;
    pictureUrl: string;
    topUserId?: string;
}

export enum DisplayNameSplit {
    FirstLast = 'FirstLast',
    LastFirst = 'LastFirst'
}
