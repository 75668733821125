import { registerHandlerHelper as t } from "../../internal/handlers.js";
import { ensureInitialized as n } from "../../internal/internalAPIs.js";
import { pagesTelemetryVersionNumber as o } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as r } from "../../internal/telemetry.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as p } from "../constants.js";
import { runtime as a } from "../runtime.js";
function i(n) {
  t(r(o, "pages.appButton.onClick"), "appButtonClick", n, [e.content], () => {
    if (!m()) throw p;
  });
}
function s(n) {
  t(r(o, "pages.appButton.onHoverEnter"), "appButtonHoverEnter", n, [e.content], () => {
    if (!m()) throw p;
  });
}
function u(n) {
  t(r(o, "pages.appButton.onHoverLeave"), "appButtonHoverLeave", n, [e.content], () => {
    if (!m()) throw p;
  });
}
function m() {
  return !(!n(a) || !a.supports.pages) && !!a.supports.pages.appButton;
}
export { m as isSupported, i as onClick, s as onHoverEnter, u as onHoverLeave };