import e from "./_polyfill-node.process.js";
import t from "./_polyfill-node._inherits.js";
var n = Object.getOwnPropertyDescriptors || function (e) {
    for (var t = Object.keys(e), n = {}, r = 0; r < t.length; r++) n[t[r]] = Object.getOwnPropertyDescriptor(e, t[r]);
    return n;
  },
  r = /%[sdj%]/g;
function o(e) {
  if (!O(e)) {
    for (var t = [], n = 0; n < arguments.length; n++) t.push(s(arguments[n]));
    return t.join(" ");
  }
  n = 1;
  for (var o = arguments, i = o.length, u = String(e).replace(r, function (e) {
      if ("%%" === e) return "%";
      if (n >= i) return e;
      switch (e) {
        case "%s":
          return String(o[n++]);
        case "%d":
          return Number(o[n++]);
        case "%j":
          try {
            return JSON.stringify(o[n++]);
          } catch (e) {
            return "[Circular]";
          }
        default:
          return e;
      }
    }), c = o[n]; n < i; c = o[++n]) h(c) || !z(c) ? u += " " + c : u += " " + s(c);
  return u;
}
function i(t, n) {
  if (w(global.process)) return function () {
    return i(t, n).apply(this, arguments);
  };
  if (!0 === e.noDeprecation) return t;
  var r = !1;
  return function () {
    if (!r) {
      if (e.throwDeprecation) throw new Error(n);
      e.traceDeprecation ? console.trace(n) : console.error(n), r = !0;
    }
    return t.apply(this, arguments);
  };
}
var u,
  c = {};
function l(t) {
  if (w(u) && (u = e.env.NODE_DEBUG || ""), t = t.toUpperCase(), !c[t]) if (new RegExp("\\b" + t + "\\b", "i").test(u)) {
    c[t] = function () {
      var e = o.apply(null, arguments);
      console.error("%s %d: %s", t, 0, e);
    };
  } else c[t] = function () {};
  return c[t];
}
function s(e, t) {
  var n = {
    seen: [],
    stylize: a
  };
  return arguments.length >= 3 && (n.depth = arguments[2]), arguments.length >= 4 && (n.colors = arguments[3]), d(t) ? n.showHidden = t : t && _(n, t), w(n.showHidden) && (n.showHidden = !1), w(n.depth) && (n.depth = 2), w(n.colors) && (n.colors = !1), w(n.customInspect) && (n.customInspect = !0), n.colors && (n.stylize = f), p(n, e, n.depth);
}
function f(e, t) {
  var n = s.styles[t];
  return n ? "[" + s.colors[n][0] + "m" + e + "[" + s.colors[n][1] + "m" : e;
}
function a(e, t) {
  return e;
}
function p(e, t, n) {
  if (e.customInspect && t && P(t.inspect) && t.inspect !== s && (!t.constructor || t.constructor.prototype !== t)) {
    var r = t.inspect(n, e);
    return O(r) || (r = p(e, r, n)), r;
  }
  var o = function (e, t) {
    if (w(t)) return e.stylize("undefined", "undefined");
    if (O(t)) {
      var n = "'" + JSON.stringify(t).replace(/^"|"$/g, "").replace(/'/g, "\\'").replace(/\\"/g, '"') + "'";
      return e.stylize(n, "string");
    }
    if (v(t)) return e.stylize("" + t, "number");
    if (d(t)) return e.stylize("" + t, "boolean");
    if (h(t)) return e.stylize("null", "null");
  }(e, t);
  if (o) return o;
  var i = Object.keys(t),
    u = function (e) {
      var t = {};
      return e.forEach(function (e, n) {
        t[e] = !0;
      }), t;
    }(i);
  if (e.showHidden && (i = Object.getOwnPropertyNames(t)), x(t) && (i.indexOf("message") >= 0 || i.indexOf("description") >= 0)) return y(t);
  if (0 === i.length) {
    if (P(t)) {
      var c = t.name ? ": " + t.name : "";
      return e.stylize("[Function" + c + "]", "special");
    }
    if (S(t)) return e.stylize(RegExp.prototype.toString.call(t), "regexp");
    if (E(t)) return e.stylize(Date.prototype.toString.call(t), "date");
    if (x(t)) return y(t);
  }
  var l,
    f = "",
    a = !1,
    m = ["{", "}"];
  (b(t) && (a = !0, m = ["[", "]"]), P(t)) && (f = " [Function" + (t.name ? ": " + t.name : "") + "]");
  return S(t) && (f = " " + RegExp.prototype.toString.call(t)), E(t) && (f = " " + Date.prototype.toUTCString.call(t)), x(t) && (f = " " + y(t)), 0 !== i.length || a && 0 != t.length ? n < 0 ? S(t) ? e.stylize(RegExp.prototype.toString.call(t), "regexp") : e.stylize("[Object]", "special") : (e.seen.push(t), l = a ? function (e, t, n, r, o) {
    for (var i = [], u = 0, c = t.length; u < c; ++u) J(t, String(u)) ? i.push(g(e, t, n, r, String(u), !0)) : i.push("");
    return o.forEach(function (o) {
      o.match(/^\d+$/) || i.push(g(e, t, n, r, o, !0));
    }), i;
  }(e, t, n, u, i) : i.map(function (r) {
    return g(e, t, n, u, r, a);
  }), e.seen.pop(), function (e, t, n) {
    var r = e.reduce(function (e, t) {
      return t.indexOf("\n"), e + t.replace(/\u001b\[\d\d?m/g, "").length + 1;
    }, 0);
    if (r > 60) return n[0] + ("" === t ? "" : t + "\n ") + " " + e.join(",\n  ") + " " + n[1];
    return n[0] + t + " " + e.join(", ") + " " + n[1];
  }(l, f, m)) : m[0] + f + m[1];
}
function y(e) {
  return "[" + Error.prototype.toString.call(e) + "]";
}
function g(e, t, n, r, o, i) {
  var u, c, l;
  if ((l = Object.getOwnPropertyDescriptor(t, o) || {
    value: t[o]
  }).get ? c = l.set ? e.stylize("[Getter/Setter]", "special") : e.stylize("[Getter]", "special") : l.set && (c = e.stylize("[Setter]", "special")), J(r, o) || (u = "[" + o + "]"), c || (e.seen.indexOf(l.value) < 0 ? (c = h(n) ? p(e, l.value, null) : p(e, l.value, n - 1)).indexOf("\n") > -1 && (c = i ? c.split("\n").map(function (e) {
    return "  " + e;
  }).join("\n").substr(2) : "\n" + c.split("\n").map(function (e) {
    return "   " + e;
  }).join("\n")) : c = e.stylize("[Circular]", "special")), w(u)) {
    if (i && o.match(/^\d+$/)) return c;
    (u = JSON.stringify("" + o)).match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/) ? (u = u.substr(1, u.length - 2), u = e.stylize(u, "name")) : (u = u.replace(/'/g, "\\'").replace(/\\"/g, '"').replace(/(^"|"$)/g, "'"), u = e.stylize(u, "string"));
  }
  return u + ": " + c;
}
function b(e) {
  return Array.isArray(e);
}
function d(e) {
  return "boolean" == typeof e;
}
function h(e) {
  return null === e;
}
function m(e) {
  return null == e;
}
function v(e) {
  return "number" == typeof e;
}
function O(e) {
  return "string" == typeof e;
}
function j(e) {
  return "symbol" == typeof e;
}
function w(e) {
  return void 0 === e;
}
function S(e) {
  return z(e) && "[object RegExp]" === N(e);
}
function z(e) {
  return "object" == typeof e && null !== e;
}
function E(e) {
  return z(e) && "[object Date]" === N(e);
}
function x(e) {
  return z(e) && ("[object Error]" === N(e) || e instanceof Error);
}
function P(e) {
  return "function" == typeof e;
}
function D(e) {
  return null === e || "boolean" == typeof e || "number" == typeof e || "string" == typeof e || "symbol" == typeof e || void 0 === e;
}
function T(e) {
  return Buffer.isBuffer(e);
}
function N(e) {
  return Object.prototype.toString.call(e);
}
function F(e) {
  return e < 10 ? "0" + e.toString(10) : e.toString(10);
}
s.colors = {
  bold: [1, 22],
  italic: [3, 23],
  underline: [4, 24],
  inverse: [7, 27],
  white: [37, 39],
  grey: [90, 39],
  black: [30, 39],
  blue: [34, 39],
  cyan: [36, 39],
  green: [32, 39],
  magenta: [35, 39],
  red: [31, 39],
  yellow: [33, 39]
}, s.styles = {
  special: "cyan",
  number: "yellow",
  boolean: "yellow",
  undefined: "grey",
  null: "bold",
  string: "green",
  date: "magenta",
  regexp: "red"
};
var k = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
function A() {
  var e, t;
  console.log("%s - %s", (e = new Date(), t = [F(e.getHours()), F(e.getMinutes()), F(e.getSeconds())].join(":"), [e.getDate(), k[e.getMonth()], t].join(" ")), o.apply(null, arguments));
}
function _(e, t) {
  if (!t || !z(t)) return e;
  for (var n = Object.keys(t), r = n.length; r--;) e[n[r]] = t[n[r]];
  return e;
}
function J(e, t) {
  return Object.prototype.hasOwnProperty.call(e, t);
}
var R = "undefined" != typeof Symbol ? Symbol("util.promisify.custom") : void 0;
function B(e) {
  if ("function" != typeof e) throw new TypeError('The "original" argument must be of type Function');
  if (R && e[R]) {
    var t;
    if ("function" != typeof (t = e[R])) throw new TypeError('The "util.promisify.custom" argument must be of type Function');
    return Object.defineProperty(t, R, {
      value: t,
      enumerable: !1,
      writable: !1,
      configurable: !0
    }), t;
  }
  function t() {
    for (var t, n, r = new Promise(function (e, r) {
        t = e, n = r;
      }), o = [], i = 0; i < arguments.length; i++) o.push(arguments[i]);
    o.push(function (e, r) {
      e ? n(e) : t(r);
    });
    try {
      e.apply(this, o);
    } catch (e) {
      n(e);
    }
    return r;
  }
  return Object.setPrototypeOf(t, Object.getPrototypeOf(e)), R && Object.defineProperty(t, R, {
    value: t,
    enumerable: !1,
    writable: !1,
    configurable: !0
  }), Object.defineProperties(t, n(e));
}
function H(e, t) {
  if (!e) {
    var n = new Error("Promise was rejected with a falsy value");
    n.reason = e, e = n;
  }
  return t(e);
}
function U(t) {
  if ("function" != typeof t) throw new TypeError('The "original" argument must be of type Function');
  function r() {
    for (var n = [], r = 0; r < arguments.length; r++) n.push(arguments[r]);
    var o = n.pop();
    if ("function" != typeof o) throw new TypeError("The last argument must be of type Function");
    var i = this,
      u = function () {
        return o.apply(i, arguments);
      };
    t.apply(this, n).then(function (t) {
      e.nextTick(u.bind(null, null, t));
    }, function (t) {
      e.nextTick(H.bind(null, t, u));
    });
  }
  return Object.setPrototypeOf(r, Object.getPrototypeOf(t)), Object.defineProperties(r, n(t)), r;
}
B.custom = R;
var $ = {
  inherits: t,
  _extend: _,
  log: A,
  isBuffer: T,
  isPrimitive: D,
  isFunction: P,
  isError: x,
  isDate: E,
  isObject: z,
  isRegExp: S,
  isUndefined: w,
  isSymbol: j,
  isString: O,
  isNumber: v,
  isNullOrUndefined: m,
  isNull: h,
  isBoolean: d,
  isArray: b,
  inspect: s,
  deprecate: i,
  format: o,
  debuglog: l,
  promisify: B,
  callbackify: U
};
export { _ as _extend, U as callbackify, l as debuglog, $ as default, i as deprecate, o as format, t as inherits, s as inspect, b as isArray, d as isBoolean, T as isBuffer, E as isDate, x as isError, P as isFunction, h as isNull, m as isNullOrUndefined, v as isNumber, z as isObject, D as isPrimitive, S as isRegExp, O as isString, j as isSymbol, w as isUndefined, A as log, B as promisify };