import { DirectoryUser } from '../data/data.models';
import { CheckName } from './checks';

export type AllowedDirectoryUserKeys = Exclude<keyof DirectoryUser, 'id'>;

export abstract class IntegrityCheck {
    static checkName: string;
    constructor(protected field?: keyof DirectoryUser) {}
    abstract run(users: DirectoryUser[]): CheckResult[];
    getField(): string | undefined {
        return this.field;
    }
    getDisplayName(): string {
        return this.constructor.name + (this.field ? ` - ${this.field}` : '');
    }
    getDescription(): string {
        return '';
    }
}

export abstract class SingleUserCheck extends IntegrityCheck {
    abstract runSingle(user: DirectoryUser): CheckResult | null;

    run(users: DirectoryUser[]): CheckResult[] {
        return users.map((user) => this.runSingle(user)).filter((result) => result !== null) as CheckResult[];
    }
}

export abstract class AllUsersCheck extends IntegrityCheck {}

export interface CheckResult {
    checkName: CheckName;
    userId: string;
    message: string;
    checkIndex: number; // This links the result to the specific check configuration
    isModified: boolean;
    isFixed: boolean;
}

export interface CheckDisplayInformation {
    name: string;
    description: string;
    fields: string[];
}

export interface SerializableCheckResult {
    checkName: string;
    userId: string;
    message: string;
}

export interface DirectoryUserModification {
    id: number;
    tenantAccountId?: string;
    userAccountId?: string;
    userAccountName?: string;
    created?: string;
    lastModified?: string;
    userId: string;
    displayName: string;
    fieldName: keyof DirectoryUser;
    oldValue: string;
    newValue: string;
    status: DirectoryUserModificationStatus;
    originalStatus?: DirectoryUserModificationStatus;
}

export enum DirectoryUserModificationStatus {
    Requested = 0,
    Approved = 1,
    Applied = 2,
    Rejected = 3,
    Deleted = 4
}

export type UserAndModifications = { user: DirectoryUser; modifications: DirectoryUserModification[] };

export interface DirectoryUserModificationRequest {
    userId: string;
    field: AllowedDirectoryUserKeys;
    value: string;
    createdBy: string;
    completedBy: string;
    createdAt: string;
    modifiedAt: string;
    limitedToValidValues?: boolean;
    validValues?: string[];
}

export interface IntegrityModificationLog {
    id?: number;
    tenantAccountId?: string;
    userAccountId?: string;
    userAccountName?: string;
    created?: Date;
    userId: string;
    displayName: string;
    fieldName: string;
    oldValue: string;
    newValue: string;
}
