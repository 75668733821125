import { DirectoryUser, SortOrder } from './data.models';

export interface Category {
    id: number;
    name: string;
    slug: string;
    icon: string;
    fieldName: keyof DirectoryUser;
    items: CategoryItem[];
    isHidden?: boolean;
    defaultPictureSetKey?: string;
    timestamp: number;
    columnSettings?: CategoryColumnSettings;
    columnDefinitions?: ColumnDefinition[];
    defaultSortField?: string;
    defaultSortOrder?: SortOrder;
    patternStrings?: Record<string, string>;
}

export interface CategoryItem {
    id: number;
    categoryId: number;
    name: string;
    description?: string;
    pictureUrl?: string;
    patternUrl?: string;
    pattern?: string;
    pictureClass?: string;
    fieldValue: string;
    fieldName: keyof DirectoryUser;
    people: DirectoryUser[];
    timestamp: number;
    columnValues?: Record<string, string>;
}

export interface CustomizedCategoryItem extends CategoryItem {
    originalName: string;
    isHidden: boolean;
    customLink?: string;
}

// export interface CustomizedCategoryItem {
//     id: number;
//     categoryId: number;
//     originalName: string;
//     name: string;
//     description?: string;
//     pictureUrl?: string;
//     patternUrl?: string;
//     isHidden: boolean;
//     fieldValue: string;
//     fieldName: keyof DirectoryUser;
//     customLink?: string;
//     people: null;
//     timestamp: number;
//     columnValues?: Record<string, string>;
// }

export enum ColumnsViewType {
    Grid = 'grid',
    Table = 'table',
    Details = 'details' //info dialog
}

export interface CategoryColumnSetting {
    originalName: string;
    name: string;
    isHidden: boolean;
}

export interface UserColumnSetting {
    originalName: keyof DirectoryUser;
    name: string;
    isHidden: boolean;
}

export interface ColumnDefinition {
    name: string;
    type: ColumnType;
}

export enum ColumnType {
    String = 'string',
    Link = 'link',
    ItemCount = 'itemCount',
    ManagerCount = 'managerCount'
}

export interface Filter {
    id: number;
    name: string;
    isEnabled: boolean;
    complexConditions: ComplexCondition[];
    effects: FilterEffect[];
}

export type ComplexCondition = FilterCondition[];

export interface FilterCondition {
    fieldName: keyof DirectoryUser;
    values: string[];
    operator: FilterOperator;
    isNegated: boolean;
}

export enum FilterOperator {
    Equals = 'equals',
    DoesNotEqual = 'doesNotEqual',
    Contains = 'contains',
    DoesNotContain = 'doesNotContain',
    StartsWith = 'startsWith',
    EndsWith = 'endsWith'
}

export enum FilterEffect {
    None = 'none',
    Hide = 'hide'
}

export type CategoryColumnSettings = {
    [key in ColumnsViewType]: CategoryColumnSetting[];
};

export type UserColumnSettings = {
    [key in ColumnsViewType]: UserColumnSetting[];
};

export type ColumnSettingsModel = {
    id: number;
    userColumnSettings: UserColumnSettings;
};

export type AllColumnSettings = {
    selectedCategoryIndex?: number;
    users: UserColumnSettings;
    categories: CategoryColumnSettings[];
};

export const DefaultDirectoryUserColumnSettings: UserColumnSettings = {
    grid: [
        { originalName: 'displayName', name: 'Name', isHidden: false },
        { originalName: 'jobTitle', name: 'Job Title', isHidden: false },
        { originalName: 'city', name: 'Office', isHidden: false },
        { originalName: 'department', name: 'Department', isHidden: false }
    ],
    table: [
        //{ originalName: 'displayName', name: 'Name', isHidden: false },
        //{ originalName: 'jobTitle', name: 'Job Title', isHidden: false },
        { originalName: 'city', name: 'Office', isHidden: false },
        { originalName: 'department', name: 'Department', isHidden: false },
        { originalName: 'mail', name: 'Email', isHidden: false },
        { originalName: 'mobilePhone', name: 'Mobile', isHidden: false }
    ],
    details: [
        { originalName: 'displayName', name: 'Name', isHidden: false },
        { originalName: 'jobTitle', name: 'Job Title', isHidden: false },
        { originalName: 'city', name: 'Office', isHidden: false },
        { originalName: 'department', name: 'Department', isHidden: false },
        { originalName: 'mail', name: 'Email', isHidden: false },
        { originalName: 'mobilePhone', name: 'Mobile', isHidden: false }
    ]
};

const DefaultCategories: Category[] = [
    {
        id: 1,
        name: 'Offices',
        slug: 'offices',
        icon: 'buildings',
        fieldName: 'city',
        defaultPictureSetKey: 'cities',
        items: [],
        timestamp: Date.now(),
        defaultSortOrder: SortOrder.Asc,
        columnDefinitions: [{ name: 'Address', type: ColumnType.String }],
        columnSettings: {
            grid: [
                { originalName: 'Address', name: 'Address', isHidden: false }
                //{ originalName: 'slug', name: 'URL slug', isHidden: true }
            ],
            table: [
                { originalName: 'Address', name: 'Address', isHidden: false }
                //{ originalName: 'slug', name: 'URL slug', isHidden: true }
            ],
            details: [
                //{ originalName: 'Address', name: 'Address', isHidden: false }
                //{ originalName: 'slug', name: 'URL slug', isHidden: true }
            ]
        }
    },
    {
        id: 2,
        name: 'Departments',
        slug: 'departments',
        icon: 'building',
        fieldName: 'department',
        items: [],
        timestamp: Date.now(),
        defaultSortOrder: SortOrder.Asc
    },
    {
        id: 3,
        name: 'Job Titles',
        slug: 'jobtitles',
        icon: 'briefcase',
        fieldName: 'jobTitle',
        items: [],
        timestamp: Date.now(),
        defaultSortOrder: SortOrder.Asc
    }
];

const DefaultDemoCategories: Category[] = [
    {
        id: 1,
        name: 'Offices',
        slug: 'offices',
        icon: 'buildings',
        fieldName: 'city',
        defaultPictureSetKey: 'countries',
        items: [],
        timestamp: Date.now(),
        defaultSortOrder: SortOrder.Asc,
        columnDefinitions: [{ name: 'Address', type: ColumnType.String }],
        columnSettings: {
            grid: [
                { originalName: 'Address', name: 'Address', isHidden: false }
                //{ originalName: 'slug', name: 'URL slug', isHidden: true }
            ],
            table: [
                { originalName: 'Address', name: 'Address', isHidden: false }
                //{ originalName: 'slug', name: 'URL slug', isHidden: true }
            ],
            details: [
                //{ originalName: 'Address', name: 'Address', isHidden: false }
                //{ originalName: 'slug', name: 'URL slug', isHidden: true }
            ]
        }
    },
    {
        id: 2,
        name: 'Departments',
        slug: 'departments',
        icon: 'building',
        fieldName: 'department',
        items: [],
        timestamp: Date.now(),
        defaultSortOrder: SortOrder.Asc
    },
    {
        id: 3,
        name: 'Job Titles',
        slug: 'jobtitles',
        icon: 'briefcase',
        fieldName: 'jobTitle',
        items: [],
        timestamp: Date.now(),
        defaultSortOrder: SortOrder.Asc
    }
];

export const getDefaultCategories = (): Category[] => {
    //return default if not in demo mode
    const isDemoFlag = localStorage.getItem('isDemo');
    const isDemo = isDemoFlag === 'true';
    if (!isDemo) {
        return DefaultCategories;
    }
    return DefaultDemoCategories;
};

export type DefaultPictureSet = Record<string, string>;

export type DefaultPicturesForCategory = Record<string, DefaultPictureSet>;
