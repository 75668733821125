import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'dir-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true
})
export class FooterComponent {
    appVersion = environment.appVersion;
}
