import { createAction, props } from '@ngrx/store';
import { CategoryImage } from '../data/data.models';
import { EditedImageInfo } from '../settings/settings.reducer';

export const loadImageFromServer = createAction('[Image] Load Image from Server', props<{ imageId: string }>());
export const loadImageFromServerSuccess = createAction(
    '[Image] Load Image from Server Success',
    props<{ imageId: string }>()
);

export const loadAllImagesFromServer = createAction('[Image] Load all images from server');
export const loadAllImagesFromServerSuccess = createAction('[Image] Load all images from server Success');

//Place the image in state so that it can be saved to the LocalDB and server later.
export const editedImageInformation = createAction(
    '[Image] Update edited image information',
    props<{ editedImage: EditedImageInfo }>()
);
//Save the image to the localDB and server.
export const saveImage = createAction('[Image] Save Image', props<{ image: CategoryImage }>());
export const saveImageSuccess = createAction('[Image] Save Image Success', props<{ id: string }>());
export const saveImageFailure = createAction('[Image] Save Image Failure', props<{ error: unknown }>());
