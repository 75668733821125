function e() {
  throw new Error("setTimeout has not been defined");
}
function t() {
  throw new Error("clearTimeout has not been defined");
}
var r = e,
  n = t;
function o(t) {
  if (r === setTimeout) return setTimeout(t, 0);
  if ((r === e || !r) && setTimeout) return r = setTimeout, setTimeout(t, 0);
  try {
    return r(t, 0);
  } catch (e) {
    try {
      return r.call(null, t, 0);
    } catch (e) {
      return r.call(this, t, 0);
    }
  }
}
"function" == typeof global.setTimeout && (r = setTimeout), "function" == typeof global.clearTimeout && (n = clearTimeout);
var i,
  u = [],
  a = !1,
  c = -1;
function l() {
  a && i && (a = !1, i.length ? u = i.concat(u) : c = -1, u.length && f());
}
function f() {
  if (!a) {
    var e = o(l);
    a = !0;
    for (var r = u.length; r;) {
      for (i = u, u = []; ++c < r;) i && i[c].run();
      c = -1, r = u.length;
    }
    i = null, a = !1, function (e) {
      if (n === clearTimeout) return clearTimeout(e);
      if ((n === t || !n) && clearTimeout) return n = clearTimeout, clearTimeout(e);
      try {
        return n(e);
      } catch (t) {
        try {
          return n.call(null, e);
        } catch (t) {
          return n.call(this, e);
        }
      }
    }(e);
  }
}
function s(e) {
  var t = new Array(arguments.length - 1);
  if (arguments.length > 1) for (var r = 1; r < arguments.length; r++) t[r - 1] = arguments[r];
  u.push(new h(e, t)), 1 !== u.length || a || o(f);
}
function h(e, t) {
  this.fun = e, this.array = t;
}
h.prototype.run = function () {
  this.fun.apply(null, this.array);
};
var m = "browser",
  w = "browser",
  v = !0,
  T = {},
  p = [],
  g = "",
  d = {},
  b = {},
  y = {};
function E() {}
var N = E,
  k = E,
  D = E,
  L = E,
  x = E,
  A = E,
  M = E;
function z(e) {
  throw new Error("process.binding is not supported");
}
function j() {
  return "/";
}
function q(e) {
  throw new Error("process.chdir is not supported");
}
function B() {
  return 0;
}
var C = global.performance || {},
  F = C.now || C.mozNow || C.msNow || C.oNow || C.webkitNow || function () {
    return new Date().getTime();
  };
function G(e) {
  var t = .001 * F.call(C),
    r = Math.floor(t),
    n = Math.floor(t % 1 * 1e9);
  return e && (r -= e[0], (n -= e[1]) < 0 && (r--, n += 1e9)), [r, n];
}
var H = new Date();
function I() {
  return (new Date() - H) / 1e3;
}
var J = {
  nextTick: s,
  title: m,
  browser: v,
  env: T,
  argv: p,
  version: "",
  versions: d,
  on: N,
  addListener: k,
  once: D,
  off: L,
  removeListener: x,
  removeAllListeners: A,
  emit: M,
  binding: z,
  cwd: j,
  chdir: q,
  umask: B,
  hrtime: G,
  platform: w,
  release: b,
  config: y,
  uptime: I
};
export { k as addListener, p as argv, z as binding, v as browser, q as chdir, y as config, j as cwd, J as default, M as emit, T as env, G as hrtime, s as nextTick, L as off, N as on, D as once, w as platform, b as release, A as removeAllListeners, x as removeListener, m as title, B as umask, I as uptime, g as version, d as versions };