import { ensureInitialized as t } from "../../internal/internalAPIs.js";
import { tabsNavigateToTabHelper as r, getTabInstancesHelper as e, getMruTabInstancesHelper as n, pagesTelemetryVersionNumber as s } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as a } from "../../internal/telemetry.js";
import { runtime as o } from "../runtime.js";
function p(t) {
  return r(a(s, "pages.tabs.navigateToTab"), t);
}
function i(t) {
  return e(a(s, "pages.tabs.getTabInstances"), t);
}
function u(t) {
  return n(a(s, "pages.tabs.getMruTabInstances"), t);
}
function m() {
  return !(!t(o) || !o.supports.pages) && !!o.supports.pages.tabs;
}
export { u as getMruTabInstances, i as getTabInstances, m as isSupported, p as navigateToTab };