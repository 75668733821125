import { DataSource } from '../app.models';
import { DemoHelper } from './demo-helper';

export class DataSourceHelper {
    public static GetSelectedDataSourceId(): number {
        if (DemoHelper.IsDemoMode()) {
            return MagicDemoDataSourceId;
        }
        const id = Number(localStorage.getItem('selectedDataSourceId'));
        //make 0 if NaN
        return id || 0;
    }

    public static GetSelectedDataSourceChartId(): string {
        if (DemoHelper.IsDemoMode()) {
            return DefaultDemoSourceChartId;
        }
        return localStorage.getItem('selectedDataSourceChartId') ?? DefaultAzureSourceChartId;
    }

    public static SetSelectedDataSourceId(dataSource: DataSource) {
        localStorage.setItem('selectedDataSourceId', String(dataSource.id));
        localStorage.setItem('selectedDataSourceChartId', String(dataSource.chartId));
    }

    public static UnsetSelectedDataSourceId() {
        localStorage.removeItem('selectedDataSourceId');
        localStorage.removeItem('selectedDataSourceChartId');
    }

    public static HasPreference(): boolean {
        return !!localStorage.getItem('selectedDataSourceChartId');
    }
}

export const DefaultAzureSourceChartId = 'defaultAzureSource';
export const DefaultDemoSourceChartId = 'defaultDemoSource';
export const DefaultAzureSource: DataSource = {
    chartId: DefaultAzureSourceChartId,
    name: 'Azure Active Directory',
    id: 0
};
export const MagicMissingDataSourceId = -1;
export const MagicDemoDataSourceId = -2;
