import { appInitializeHelper as t, registerOnThemeChangeHandlerHelper as e, openLinkHelper as n } from "../internal/appHelpers.js";
import { sendMessageToParent as r } from "../internal/communication.js";
import { GlobalVars as i } from "../internal/globalVars.js";
import { registerHandlerHelper as o } from "../internal/handlers.js";
import { ensureInitializeCalled as a, ensureInitialized as s } from "../internal/internalAPIs.js";
import { getTabInstancesHelper as c, getMruTabInstancesHelper as l, shareDeepLinkHelper as u, setCurrentFrameHelper as f } from "../internal/pagesHelpers.js";
import { getApiVersionTag as m } from "../internal/telemetry.js";
import { getGenericOnCompleteHandler as p } from "../internal/utils.js";
import { FrameContexts as g } from "./constants.js";
import { runtime as H } from "./runtime.js";
import { enablePrintCapability as b, print as d, registerOnLoadHandlerHelper as h, registerBeforeUnloadHandlerHelper as C } from "./teamsAPIs.js";
import { registerBackButtonHandlerHelper as j } from "./pages/backStack.js";
const x = "v1";
function B(e, n) {
  t(m(x, "initialize"), n).then(() => {
    e && e();
  });
}
function k() {
  b();
}
function v() {
  d();
}
function E(t) {
  a(), r(m(x, "getContext"), "getContext", e => {
    e.frameContext || (e.frameContext = i.frameContext), t(e);
  });
}
function L(t) {
  e(m(x, "registerOnThemeChangeHandlerHelper"), t);
}
function P(t) {
  o(m(x, "registerFullScreenHandler"), "fullScreenChange", t, []);
}
function I(t) {
  o(m(x, "registerAppButtonClickHandler"), "appButtonClick", t, [g.content]);
}
function S(t) {
  o(m(x, "registerAppButtonHoverEnterHandler"), "appButtonHoverEnter", t, [g.content]);
}
function y(t) {
  o(m(x, "registerAppButtonHoverLeaveHandler"), "appButtonHoverLeave", t, [g.content]);
}
function A(t) {
  j(m(x, "registerBackButtonHandler"), t);
}
function F(t) {
  h(m(x, "registerOnLoadHandler"), t);
}
function T(t) {
  C(m(x, "registerBeforeUnloadHandler"), t);
}
function U(t) {
  o(m(x, "registerFocusEnterHandler"), "focusEnter", t, []);
}
function W(t) {
  o(m(x, "registerChangeSettingsHandler"), "changeSettings", t, [g.content]);
}
function z(t, e) {
  s(H), c(m(x, "getTabInstances"), e).then(e => {
    t(e);
  });
}
function D(t, e) {
  s(H), l(m(x, "getMruTabInstances"), e).then(e => {
    t(e);
  });
}
function O(t) {
  u(m(x, "shareDeepLink"), {
    subPageId: t.subEntityId,
    subPageLabel: t.subEntityLabel,
    subPageWebUrl: t.subEntityWebUrl
  });
}
function M(t, e) {
  s(H, g.content, g.sidePanel, g.settings, g.task, g.stage, g.meetingStage);
  const r = null != e ? e : p();
  n(m(x, "executeDeepLink"), t).then(() => {
    r(!0);
  }).catch(t => {
    r(!1, t.message);
  });
}
function V(t) {
  f(m(x, "setFrameContext"), t);
}
function q(e, n, r) {
  t(m(x, "initializeWithFrameContext"), r).then(() => n && n()), f(m(x, "setFrameContext"), e);
}
export { k as enablePrintCapability, M as executeDeepLink, E as getContext, D as getMruTabInstances, z as getTabInstances, B as initialize, q as initializeWithFrameContext, v as print, I as registerAppButtonClickHandler, S as registerAppButtonHoverEnterHandler, y as registerAppButtonHoverLeaveHandler, A as registerBackButtonHandler, T as registerBeforeUnloadHandler, W as registerChangeSettingsHandler, U as registerFocusEnterHandler, P as registerFullScreenHandler, F as registerOnLoadHandler, L as registerOnThemeChangeHandler, V as setFrameContext, O as shareDeepLink };