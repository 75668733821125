import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'img[dirImageFallback]',
    standalone: true
})
export class ImageFallbackDirective {
    @Input() dirImageFallback: string;
    hasFailed = false;
    constructor(private elementRef: ElementRef) {}

    @HostListener('error')
    onError() {
        if (!this.hasFailed) {
            this.elementRef.nativeElement.src = this.dirImageFallback || 'assets/img/person96.png';
        }
        this.hasFailed = true;
        //console.log('error');
    }
}
