import { sendAndHandleStatusAndReason as e } from "../internal/communication.js";
import { createTeamsDeepLinkForChat as s } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { FrameContexts as n, errorNotSupportedOnPlatform as o } from "./constants.js";
import { runtime as i } from "./runtime.js";
function a(e) {
  return m(r("v2", "chat.openChat"), e);
}
function m(r, a) {
  return new Promise(m => {
    if (t(i, n.content, n.task), !u()) throw o;
    if (i.isLegacyTeams) m(e(r, "executeDeepLink", s([a.user], void 0, a.message)));else {
      m(e(r, "chat.openChat", {
        members: [a.user],
        message: a.message
      }));
    }
  });
}
function c(a) {
  const c = r("v2", "chat.openGroupChat");
  return new Promise(r => {
    if (a.users.length < 1) throw Error("OpenGroupChat Failed: No users specified");
    if (1 === a.users.length) {
      const e = {
        user: a.users[0],
        message: a.message
      };
      r(m(c, e));
    } else {
      if (t(i, n.content, n.task), !u()) throw o;
      if (i.isLegacyTeams) r(e(c, "executeDeepLink", s(a.users, a.topic, a.message)));else {
        r(e(c, "chat.openChat", {
          members: a.users,
          message: a.message,
          topic: a.topic
        }));
      }
    }
  });
}
function u() {
  return !(!t(i) || !i.supports.chat);
}
export { u as isSupported, a as openChat, c as openGroupChat };