<!--begin::Footer-->
<div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <!--begin::Copyright-->
        <div class="text-dark order-2 order-md-1">
            <span class="text-muted fw-bold me-1">2024©</span>
            <a href="https://teamimprover.com" target="external" class="text-gray-800 text-hover-primary">
                TeamImprover.Com Ltd
            </a>
            <span class="text-muted ms-2">TeamDirectory version: {{ appVersion }}</span>
        </div>
        <!--end::Copyright-->
        <!--begin::Menu-->
        <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li class="menu-item">
                <a href="https://help.team-directory.com/" target="team-directory-help" class="menu-link px-2">Guide</a>
            </li>
            <li class="menu-item">
                <a href="mailto:info@Teamimprover.com" target="external" class="menu-link px-2">Support</a>
            </li>
            <li class="menu-item">
                <a href="https://team-directory.com/privacy" target="external" class="menu-link px-2">Privacy</a>
            </li>
            <li class="menu-item">
                <a href="https://www.team-directory.com/terms-of-service" target="external" class="menu-link px-2">
                    Terms
                </a>
            </li>
        </ul>
        <!--end::Menu-->
    </div>
    <!--end::Container-->
</div>
<!--end::Footer-->
