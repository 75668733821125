import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, EMPTY, from, map, switchMap, of } from 'rxjs';
import { AppDB } from './db.service';

@Injectable({
    providedIn: 'root'
})
export class GraphImageService {
    constructor(
        private http: HttpClient,
        private db: AppDB
    ) {}

    getImage(userId: string, isCurrentUser = false): Observable<string> {
        if (localStorage.getItem('isDemo') === 'true') {
            return of('');
        }
        const url = isCurrentUser
            ? 'https://graph.microsoft.com/v1.0/me/photos/648x648/$value'
            : `https://graph.microsoft.com/v1.0/users/${userId}/photos/648x648/$value`;

        const request = this.http.get<Blob>(url, { responseType: 'blob' } as object).pipe(
            switchMap((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);

                return new Observable<string>((observer) => {
                    reader.onload = () => {
                        observer.next(reader.result as string);
                        observer.complete();
                        this.db.instance.userPhotos.put({ id: userId, photo: reader.result as string });
                    };
                    reader.onerror = () => {
                        observer.error(reader.error);
                    };
                });
            }),
            catchError((error: HttpErrorResponse) => {
                //console.log(error);
                if (error.status === 404) {
                    this.db.instance.userPhotos.put({ id: userId, photo: '' });
                    return of('');
                }
                return EMPTY;
            })
        );
        return from(this.db.instance.userPhotos.get(userId)).pipe(
            switchMap((photo) => {
                if (photo) {
                    return of(photo.photo);
                } else {
                    return request;
                }
            })
        );
    }
}
