import { validateStringAsAppId as t } from "../internal/appIdValidation.js";
class r {
  constructor(r) {
    this.appIdAsString = r, t(r);
  }
  serialize() {
    return this.toString();
  }
  toString() {
    return this.appIdAsString;
  }
}
export { r as AppId };